@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700');
@import 'typography';
@import 'colors';

$default-font-family: var(--font-standard), 'Inter';

html {
  box-sizing: border-box;
  width: 100%;
}
body {
  // min-width: 375px;
  font-family: $default-font-family;
  font-size: 16px;
  overscroll-behavior: none;
  text-align: center;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.faux-link,
a:link,
a:visited,
a:hover {
  color: var(--color-charcoal);
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
}
// .inverse {
//   .faux-link,
//   a:link,
//   a:visited,
//   a:hover {
//     color: #fff !important;
//   }
// }
.left {
  text-align: left;
}
.show {
  display: inherit;
}
.hide {
  display: none !important;
}

.faux-link.muted {
  font-weight: bold;
}

ul.left-list-inverse {
  text-align: left;
  color: $med-light-text-color;
  padding-left: 16px;
}

.darkText {
  color: $dark-text-color !important;
}

.bold {
  font-weight: 600 !important;
}

.apply-background-content {
  background-color: #fff;
  // background-image: url(https://media.tento.co/backgrounds/tento-grain-bg-light.png);
  background-image: url(https://media.tento.co/backgrounds/apply.jpeg);
  background-position: 50%;
  background-size: cover;
  min-height: 100vh;
}

.sb-show-main {
  padding: 0px !important;
}

a.arrow-link {
  font-weight: 400;
  text-decoration: none;
  font-size: 18px;
  line-height: 27px;
  &.font-bold {
    font-weight: 700;
  }
  &:hover {
    text-decoration: underline;
  }
  svg {
    display: inline;
  }
}
.term-radio-grid-item {
  font-family: 'Apercu';
  input {
    font-family: var(--font-standard), 'Inter';
  }
  label {
    font-family: var(--font-standard), 'Inter';
    font-weight: 700;
    letter-spacing: 1.2px;
    // line-height: normal;
  }
}
.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
  fieldset {
    border-color: var(--color-charcoal);
  }
}

label.MuiFormLabel-root.MuiInputLabel-outlined.Mui-focused {
  color: var(--default-primary-color);
}
.tento-select {
  .MuiInputBase-input {
    padding: 10px !important;
    background: transparent !important;
  }
}

input[type='checkbox'].tento-checkbox:focus {
  outline: none !important;
}

.geosuggest__suggests-wrapper {
  z-index: 9999;
}
.MuiCard-root {
  overflow: visible !important;
}

.geosuggest__suggests--hidden {
  padding: 0px;
}
