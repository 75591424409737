//loader
.default-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.default-spinner div {
  transform-origin: 30px 30px;
  animation: default-spinner 0.8s linear infinite;
}

.default-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 30px;
  width: 2px;
  height: 10px;
  border-radius: 1px;
  background: $info;
}

.default-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.7s;
}

.default-spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.6s;
}

.default-spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.5s;
}

.default-spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.4s;
}

.default-spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.3s;
}

.default-spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.2s;
}

.default-spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.1s;
}

.default-spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0s;
}

@keyframes default-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
