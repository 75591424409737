$primary-color: var(--default-primary-color);
$primary-rgba: var(--default-primary-rgba);
$hover-color: var(--default-hover-color);

$secondary-color: #ababab;
$secondary-hover-color: #999;

/* New Colors: v1 */
$primary-c: #42bc96;
$secondary-c: #b1a46a;
$complimentary-dark-c: #2d3943;
$complimentary-dark-c-rgb: 45, 57, 67;
$complimentary-light-c: #9bc0e2;

$complimentary-dark-selected: #2a4f71;
$primary-selected-c: #2cad85;

$success: $primary-c;
$info: #398ef8;
$danger: #eb6969;

$gray-0: #1a1a1a;
$gray-1: #5b5b5b;
$gray-2: #8f8f8f;
$gray-3: #d4d4d4;
/* New Colors: v1 */

$background-color: #ffffff;
$dark-text-color: #1a1a1a;
$med-text-color: $gray-1;
$med-light-text-color: #8f8f8f;
$light-text-color: #aaaaaa;
$white-text-color: #ffffff;

$error-color: var(--color-zest);
// $error-color: #f44336;
// $error-color: #ffae42;

$footer-text-color: #2c3842;
$footer-bg-color: #e7e7e7;

$border-color: var(--color-charcoal);
$med-border-color: var(--color-charcoal);
$light-border-color: var(--color-charcoal);

:root {
  --complimentary-dark-c-rgb: $complimentary-dark-c-rgb;
  --info: #398ef8;
}
:export {
  primary: $primary-color;
  primaryRgba: $primary-rgba;
  hover: $hover-color;
  secondary: $secondary-color;
  secondaryHover: $secondary-hover-color;
}
