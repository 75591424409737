@import '../../../../scss/processing';
@import '../../../../scss/colors';
@import '../../../../scss/typography';

.basic-modal,
.confirm-modal,
.done-modal {
  width: 400px;
  margin: 0 auto;
  margin-top: 80px;
}

@media (max-width: 460px) {
  .done-modal {
    width: calc(100vw - 30px);
    margin: 0 auto;
  }
}

.processing-modal {
  width: calc(100vw - 21px * 2);
  margin: 0 21px;
  .icon {
    max-height: 300px;
  }
}

.processing-modal,
.confirm-modal,
.done-modal {
  font-family: var(--font-standard);

  h1 {
    font-weight: 600;
    font-size: 34px;
    text-align: center;
    letter-spacing: -0.3008px;
    color: $dark-text-color;
    margin: 0;
  }

  h5,
  p {
    @include t5;
    color: $med-light-text-color;
    font-weight: 400;
    letter-spacing: -0.3008px;
    margin: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    letter-spacing: -0.3008px;
    color: $dark-text-color;
    margin: 0;
  }

  p {
    color: $med-light-text-color;
  }

  .questions p {
    margin-bottom: 11px;
  }

  .questions a.mailto_hello {
    font-weight: 600;
    color: $primary-color !important;
  }

  .brandFullLogo {
    height: 65px;
    margin-bottom: 53px;
  }

  .MuiButton-root {
    box-sizing: border-box;
    letter-spacing: normal;
    border-radius: 0px;
    width: 360px;
    margin-top: 60px;
  }

  .MuiButton-label {
    box-sizing: border-box;
    text-transform: uppercase;
    font-family: var(--font-standard);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 14px 0;
  }

  .done-modal_content {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-top: 56px;
  }

  h4.blue {
    color: rgb(46, 56, 65);
  }

  h4.tiny {
    font-size: 0.8rem;
  }

  .btn-logout {
    background-color: #fff !important;
    color: $primary-color !important;
    box-shadow: none !important;

    &:hover {
      background-color: #eee !important;
    }

    &:active {
      background-color: #eee !important;
    }
  }
}

.processing-modal {
  .title {
    color: $dark-text-color;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    letter-spacing: -0.3008px;
  }

  .brandLogo {
    height: 80px;
    margin-bottom: 62px;
  }

  h5 {
    @include t5;
    color: $med-light-text-color;
    font-weight: 400;
    letter-spacing: -0.3008px;
    margin: 0;
  }

  //loader
  .lds-spinner {
    margin-top: 80px;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-spinner div {
    transform-origin: 30px 30px;
    animation: lds-spinner 0.8s linear infinite;
  }

  .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 30px;
    width: 2px;
    height: 10px;
    border-radius: 1px;
    background: $primary-color;
  }

  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.7s;
  }

  .lds-spinner div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -0.6s;
  }

  .lds-spinner div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.5s;
  }

  .lds-spinner div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.4s;
  }

  .lds-spinner div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.3s;
  }

  .lds-spinner div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.2s;
  }

  .lds-spinner div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.1s;
  }

  .lds-spinner div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: 0s;
  }

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

hr.divider {
  border: 0;
  height: 2px;
  width: 80%;
  background-color: $primary-color;
}

.confirm-modal {
  .title {
    margin-bottom: 50px;
  }

  .paymentText,
  .dailyText,
  .paymentAmount,
  .interestAmount {
    color: rgb(46, 56, 65) !important;
  }

  .payment {
    border-bottom: solid 1px var(--color-charcoal);
  }

  .monthlyPayment {
    border-bottom: solid 1px var(--color-charcoal) !important;
  }

  .btn-confirm {
    width: 180px;
    color: #fff;
    background-color: rgb(46, 56, 65) !important;
    padding: 12px !important;
    text-transform: none;
  }

  .closeConfirmModal {
    text-decoration: underline;
    color: rgb(46, 56, 65);
    cursor: pointer;
  }
}

.loanOverview {
  width: 95%;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 auto 25px;
  padding: 10px 0;

  .loanAmountBox,
  .termLengthBox {
    width: 50%;
    text-align: center;
    float: left;
  }

  .loanAmountBox {
    border-right: solid 1px var(--color-charcoal);
  }

  p {
    margin: 10px;
    font-size: 0.8rem;
    font-weight: lighter;
  }

  p.big {
    font-size: 1.3rem;
    font-weight: normal;
  }
}

.done-modal-logout {
  margin: 0 auto;
}
