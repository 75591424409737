@import '../../../scss/typography';
@import '../../../scss/colors';

.muiCustomSelect {
  width: 100%;
}
.muiCustomSelect .MuiFilledInput-root {
  text-align: left;
  padding-left: 17px;
  background: #ffffff;
  border: 1px solid #d5cddd !important;
  border-radius: 4px !important;
}
.muiCustomSelect.mui-input-custom div.MuiSelect-select {
  border: none;
}
.w100 {
  width: 100%;
}
.app-input label.infoFormCustomLabel,
.infoFormCustomLabel {
  display: block;
  width: fit-content;
  // font-weight: 600;
  line-height: 130%;
  @include s2;
  text-align: left;
  color: $med-text-color;
  margin-bottom: 8px;
}
