@import 'typography';
@import 'colors';
@import 'mixins';

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

header,
main,
footer {
  flex-shrink: 0 !important;
}

main {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  flex-basis: auto;
}

.app-section {
  // margin: 29px auto;
  font-family: var(--font-standard), 'Inter';
  // padding: 0 16px;
  max-width: 100%;
}
.app-section,
.app-section form,
.app-section .form-width {
  max-width: 100%;
  // margin-bottom: 24px;
  .slideWidth {
    // max-width: 450px;
    margin: 0 auto;
  }
}

.app-section .docusign {
  width: 300px;
  margin: 0 auto;
}

.app-section h2 {
  @include t1;
  // color: $primary-color; // IN NEW THEME IT SHOULD BE BLACK - added in relevant Heading2
  // font-weight: 500;
  // margin: 10px 0 30px;
}

.app-section h3 {
  @include t2;
  color: $dark-text-color;
  // margin: 0;
  // font-weight: 600;
  // letter-spacing: -0.3008px;
}

.app-section h4 {
  @include t4;
  color: $dark-text-color;
  // margin: 0 0 15px;
  // font-weight: 600;
  // letter-spacing: -0.3008px;
  // margin: 0;
}

.app-section h5 {
  @include t5;
  color: $med-light-text-color;
  // font-weight: 400;
  // letter-spacing: -0.3008px;
  // margin: 0;
}

.app-section .fieldLabel {
  @include s3;
  color: $med-text-color;
  // font-weight: 600;
  text-align: left;
}

.smallInfoText {
  @include s4;
  font-weight: 400;
  color: $med-light-text-color;
  text-align: left;
}

/* animation for swiping questions */
.slide {
  // position: absolute;
  width: 100%;
}

.slide-enter {
  // transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  // transform: translateX(0);
  // transition: transform 250ms ease-in;
}

.slide-exit {
  // transform: translateX(0);
}

.slide-exit.slide-exit-active {
  // transform: translateX(-100%);
  // transition: transform 250ms ease-in;
}

.slideBack {
  position: absolute;
  width: 100%;
}

.slideBack-enter {
  // transform: translateX(-100%);
}

.slideBack-enter.slideBack-enter-active {
  // transform: translateX(0);
  // transition: transform 250ms ease-in;
}

.slideBack-exit {
  // transform: translateX(0);
}

.slideBack-exit.slideBack-exit-active {
  // transform: translateX(200%);
  // transition: transform 250ms ease-in;
}

/* end animations style */

.alert-loader {
  margin-left: 5px;
}

.alert-message > div:first-child {
  width: 80%;
}
