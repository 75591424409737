.file-upload {
}
.file-upload-label {
  margin-top: 30px;
  text-align: center;
}
.file-upload-label svg {
  margin-left: auto;
  margin-right: auto;
}
