@import '../../../../scss/typography';
@import '../../../../scss/colors';

// for Terms.jsx ??
h4.loanTermsMsg {
  margin: 30px 0 0 0;
  font-size: 1rem;
}
h4.selectTerm {
  margin: 30px 0 15px;
}
.disclaimer {
  @include s2;
  margin-top: 22px;
}
//----

.amountSection {
  .app-input {
    margin-top: 8px;
    margin-bottom: 26px;
  }
  .amount {
    width: 100% !important;
  }
  .amount input {
    font-weight: 400;
    color: $med-text-color;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid var(--color-charcoal);
    box-shadow: 0px 0px 8px rgba(var(--complimentary-dark-c-rgb), 0.3);
    border-radius: 4px;
    text-align: right;
    padding: 16px 32px 18px 16px;
  }
  .estimatedPayment {
    font-weight: 400;
    @include s4;
    line-height: 0.938rem;
    text-align: center;
    letter-spacing: -0.3008px;
    color: $primary-color;
  }
}
// range input styles are in the forms.scss file

.noticeBanner {
  background-color: #fdf3cd;
  padding: 8px 10px;
  border-radius: 2px;
}
