@import '../../../../scss/typography';
@import '../../../../scss/colors';

.consent-box {
}

.custiomMuiInput {
  display: flex;
  flex-direction: column;
  width: 335px;

  .input-50 {
    width: 159.5px;
  }
}

.input50Container {
  display: flex;
  gap: 16px;
}
