@import '../../../../scss/typography';
@import '../../../../scss/colors';

.close-icon.force-show {
  opacity: 1 !important;
}
.geosuggest__suggests {
  border: solid 1px var(--color-charcoal);
  text-align: left;
  max-height: 25em;
  padding: 0;
  background: #fff;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  position: relative;
  z-index: 25;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}
.geosuggest__item {
  border: solid 1px var(--color-charcoal);
  cursor: pointer;
  padding: 0 3px;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #eee;
}
.geosuggest__item--active {
  background: #eee;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.custom-suggest-item {
  padding: 3px 10px;
  font-weight: lighter;
}
.custom-suggest-item .street {
  font-size: 18px;
  color: $dark-text-color;
}
.custom-suggest-item .city-state {
  @include s1;
  color: $light-text-color;
}
/* end geosuggest */

.manual-addr-street {
  margin-top: -15px !important;
}
.manual-address-override {
  margin: 15px 0 0;
}
