:root {
  // LOGO
  --logoScalePercent: 100;
  --logoPosition: left;

  // FONT SIZES:
  --t1: 1.9rem;
  // main heading size
  --t2: 1.5rem;
  --t2LineH: 1.875rem;

  --t3: 1.3rem;
  // h4 heading size
  --t4: 1.25rem;
  --t4LineH: 1.563rem;
  // paragraph size
  --t5: 1.125rem;
  --t5LineH: 1.25rem;

  --s0: 1rem;
  --s1: 0.9rem;
  // label sizes
  --s2: 0.8rem;
  --s3: 0.875rem;
  // Info Text Size
  --s4: 0.75rem;

  --inputPlaceholderSize: 16px;
  --inputErrorSize: 10px;

  // FONT FAMILY:
  --mainFont: 'Inter';
}
