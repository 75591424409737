@import '../../../scss/typography';
@import '../../../scss/colors';

.homeCta {
  margin: 0 auto;
}

.ctaLinks {
  margin: 40px 0;
  width: 100%;
}

.typedInput {
  @include t1;
  color: $dark-text-color;
  font-weight: bold;
  border: none;
  background: transparent;
  width: 135px;
  height: 50px;
  margin-left: 10px;
}

.btn-home {
  font-weight: lighter;
  width: 120px;
  margin: 5px;
}

.signIn {
  color: $primary-color;
  clear: both;
  padding-top: 35px;
  width: 100%;
  font-weight: bold;
}

.securityMsg {
  @include s2;
  color: $light-text-color;
  margin: 0 auto 40px;
  width: 70%;
  font-weight: 300;
}

.needAssistance {
  color: $med-text-color;
  font-weight: bold;
  width: 80%;
  margin: 0 auto 50px;
  padding: 35px 0;
  border-bottom: solid 2px var(--color-charcoal);
  span {
    border-left: solid 1px var(--color-charcoal);
    padding-left: 15px;
    margin-left: 15px;
  }
}

.googleReviews {
  color: $light-text-color;
  width: 100%;
  font-weight: 300;

  img {
    margin-bottom: 20px;
  }

  a:link,
  a:visited,
  a:hover {
    color: #235177;
    font-weight: 300;
  }
}

.trustLogos {
  margin: 65px 0 15px 0;

  div {
    float: left;
    width: 33%;
  }

  .bbb {
    width: 100px;
  }
  .ssl {
    width: 75px;
    margin-top: 5px;
  }
  .docusign {
    width: 105px;
    margin-right: 10px;
  }
}
