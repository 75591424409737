$loader--width: 250px;
$loader-dot--size: 20px;

// .container {
//   height: 50px;
// }

.loader {
  height: $loader-dot--size;
  width: $loader--width;
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  &--dot {
    animation: loader ease-in-out 3s infinite;
    height: $loader-dot--size;
    width: $loader-dot--size;
    border-radius: 100%;
    background-color: black;
    position: absolute;

    &:first-child {
      background-color: #fff;
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      background-color: #fff;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      background-color: #fff;
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      background-color: #fff;
      animation-delay: 0.2s;
    }
    &:nth-child(5) {
      background-color: #fff;
      animation-delay: 0.1s;
    }
    &:nth-child(6) {
      background-color: #fff;
      animation-delay: 0s;
    }
  }
  &--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;

    &:after {
      content: 'Processing';
      font-weight: bold;
      color: #fff;
      animation: loading-text 3s infinite;
    }
  }
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX($loader--width - $loader-dot--size);
  }
  65% {
    transform: translateX($loader--width - $loader-dot--size);
  }
  95% {
    transform: translateX(0);
  }
}

@keyframes loading-text {
  0% {
    content: 'Processing';
  }
  25% {
    content: 'Processing.';
  }
  50% {
    content: 'Processing..';
  }
  75% {
    content: 'Processing...';
  }
}
