@import url('https://fonts.googleapis.com/css?family=Merriweather');

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/SourceSansPro200.woff2') format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/SourceSansPro300.woff2') format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/SourceSansPro400.woff2') format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/SourceSansPro600.woff2') format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/SourceSansPro700.woff2') format('woff2');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/SourceSansPro900.woff2') format('woff2');
}
// --- NEW FONT FOR TESTING ---
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/INTER.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Merriweather-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Apercu Medium';
  font-style: normal;
  font-weight: 500;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Apercu Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Apercu Regular';
  font-style: normal;
  font-weight: 400;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Apercu Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 300;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Apercu Pro Light.otf') format('opentype');
}

@font-face {
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 700;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Apercu Pro Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Apercu';
  font-style: normal;
  font-weight: 400;
  src: url('https://s3.amazonaws.com/media.tento.co/fonts/Apercu Pro Regular.otf') format('opentype');
}
