@import '../../../../scss/colors';
@import '../../../../scss/typography';

.plaid-button-wrap {
  padding: 30px 0;
}

.btn-plaid {
  width: 100%;
}
@media (min-width: 768px) {
  .btn-plaid {
    width: 360px;
  }
}

.btn-plaid.disabled {
  opacity: 0.2;
  cursor: default;
}

.security-msg {
  @include s1;
  color: $dark-text-color;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.3008px;
}

.connect-link {
  font-weight: 400 !important;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  letter-spacing: -0.3008px;
  text-decoration-line: underline;
  color: $primary-color;
  display: block;
}

.uploadSlide .muted {
  font-weight: 400;
}

.filepond--root.filepond--hopper {
  outline: 1px dashed $info;
  border-radius: 8px;
  width: 100%;
  min-height: 110px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .filepond--root.filepond--hopper {
    width: 335px;
  }
}

.filepond--root .filepond--drop-label {
  min-height: 110px !important;
}

.drop-svg {
  path {
    stroke: $primary-color;
  }
}

.filepond--root {
  font-family: var(--font-standard);
}

.filepond--drop-label.filepond--drop-label label {
  color: $med-light-text-color;
}
.filepond--file-info .filepond--file-info-main {
  text-align: left;
}
.filepond--label-action {
  color: $primary-color;
  text-decoration-color: $primary-color !important;
}

.filepond--file-wrapper {
  background-color: #8f8f8f;
  border-radius: 8px;
  min-height: 48px;
}

.filepond--file-status-sub {
  display: none;
}

.bank-options-wrap {
}

.bank-options-wrap p {
  color: $med-text-color;
  font-weight: 600;
  @include t5;
  margin-bottom: 23px;
}

/* customize the uploaded file div to match theme */
[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: $primary-color !important;
}

ul.accountList {
  list-style-type: none;
  padding-left: 0;
}

ul.accountList li {
  height: 25px;
  line-height: 25px;
  border-radius: 3px;
  margin: 5px 10px;
}

ul.accountList li:hover {
  background: $hover-color;
  color: $white-text-color;
  cursor: pointer;
}

.BankEmailSlide {
  .btn-continue {
    max-width: 360px;
    background: #ffffff !important;
    color: $primary-color !important;
    border: 1px solid var(--color-charcoal) !important;
  }
  .btn-continue .MuiButton-label {
    text-transform: none !important;
  }
}
.muted {
  color: $med-light-text-color;
}
.med_dark_text {
  color: $med-text-color;
  font-weight: 600;
}
.connectSlide .form-width,
.uploadSlide .form-width {
  max-width: 360px;
}
.uploadSlide .btn-continue {
  width: 360px;
}
.uploadSlide .uploadContinueButtonForm {
  margin: 0;
}
.connectSlide {
  img {
    margin-top: 24px;
    margin-bottom: 24px;
    max-height: 140px;
  }
}

.bank-options-link-wrap {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}
