@import 'typography';
@import 'colors';
@import 'mixins';

button:focus,
input:focus {
  outline: none !important;
  outline-color: transparent;
  outline-style: none;
}
/* disable input shadows on ios safari */
input[type='text'],
input[type='tel'],
input[type='text'],
input[type='number'],
input[type='email'] {
  appearance: none;
}
/* disable the spinner on number input */
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* end - disable the spinner on number input */

/* input and label style */
.app-input {
  // margin: 15px auto;
}
.app-input label {
  @include s1;
  // color: $med-light-text-color;
  // font-weight: 400;
  // display: block;
  // margin: 5px 0;
}
.app-input input {
  @include t3;
  // color: $dark-text-color;
  // border: solid 1px $med-border-color;
  // line-height: 2rem;
  // margin: 0 auto;
  // display: block;
  // width: 100%;
  // background: #fff;
  // border-radius: 4px;
  // padding: 5px;
  // height: 56px;
  // text-align: left;
  // padding-left: 17px;
  // padding-right: 34px;
}
.wrap-input {
  position: relative;
  margin: 0 auto;
}
.wrap-input .error-text {
  top: 3px;
}

.label-above {
  @include s1;
  color: $med-light-text-color;
  font-weight: 400;
  display: block;
  margin: 20px 0 5px;
}

.error-text {
  color: $error-color !important;
  font-size: var(--inputErrorSize);
  top: 6px;
  left: 10px;
  text-align: left;
}

.error-color {
  color: $error-color !important;
}
/* end - validation css */

/* start custom material-ui overrides */
div.inline {
  display: inline;
  position: relative;
}
.mui-input-custom {
  margin: 0;
}
.mui-input-custom > div:before,
.mui-input-custom > div:after,
.mui-date-picker > div:before,
.mui-date-picker > div:after {
  border: none !important;
}
.mui-input-custom input:focus {
  border-color: $primary-color;
}
.mui-select div.Mui-focused {
  border: solid 1px var(--color-charcoal) !important;
}
.mui-input-custom > div,
.mui-input-custom > div:before,
.mui-input-custom > div:after,
.mui-date-picker > div,
.mui-date-picker > div:before,
.mui-date-picker > div:after {
  border-bottom: none;
}
.mui-input-custom input,
.mui-input-custom div.MuiSelect-select {
  border: solid 1px var(--color-charcoal);
  border-radius: 1px;
  padding: 20px 0 7px 10px;
  font-weight: 300;
}
.mui-input-custom label[data-shrink='true'] {
  position: absolute;
  top: 20px;
  left: 10px;
  font-size: 13px;
}
.mui-input-custom label[data-shrink='false'] {
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: unset;
}
.mui-input-custom.invalid label[data-shrink='true'] {
  display: none;
}
.input-100 {
  width: 100%;
}
.input-50 {
  width: 49%;
}
.input-50.mr {
  margin-right: 2%;
}

.mui-date-picker input {
  @include t3;
  text-align: left;
}
.mui-date-picker {
  border: solid 1px var(--color-charcoal) !important;
  border-radius: 10px;
  width: 100%;
  min-width: 200px !important;
}
@media (min-width: 768px) {
  .mui-date-picker {
    min-width: 320px !important;
  }
}
.invalid .mui-date-picker:focus-within,
.mui-date-picker:focus-within {
  border: solid 1px var(--color-charcoal) !important;
  border-radius: 100px;
  background: var(--color-bg-dawn);
}
.invalid .mui-date-picker::placeholder,
.mui-date-picker::placeholder {
  font-style: italic !important;
  color: var(--color-charcoal) !important;
}
.mui-date-picker p {
  color: $error-color;
  text-align: center;
  width: 100%;
}
.invalid .mui-date-picker {
  border-color: var(--color-zest) !important;
}
/* end custom material ui overrides */

input[type='checkbox'] {
  margin-right: 12px;
}
input[type='checkbox']:focus {
  outline: 3px solid var(--color-charcoal) !important;
}

/* new payment selection radio buttons */
fieldset {
  font-family: var(--font-standard), 'Inter';
  text-align: center;
  border: none;
  padding-right: 0;
  padding-left: 0;
}
.term-radio {
  display: inline-block;
  margin: 5px;
}
.term-radio input[type='radio'] {
  display: none;
}
.term-radio input[type='radio'] + label {
  display: block;
  height: 60px;
  width: 60px;
  padding: 12px;
  border-radius: 30px;
  box-sizing: border-box;
  font-size: 30px;
  line-height: 37px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border: none;
  background: #9f9f9f;
  position: relative;
  cursor: pointer;
}
.term-radio input[type='radio']:checked + label,
.term-radio input[type='radio'] + label:hover {
  background: $primary-color;
}
/* end new payment selection radio buttons */

/* eq grid layout for terms */
.eq-term-payment-grid {
  display: grid;
  grid-template-columns: 166px 166px;
  grid-template-rows: 1fr 1fr;
  flex-wrap: wrap;
  column-gap: 7px;
  row-gap: 8px;
}

.term-radio-grid-item {
  width: 166px;
  float: left;
  box-sizing: border-box;
}

@media (max-width: 400px) {
  .eq-term-payment-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}

.term-radio-grid-item input[type='radio'] {
  display: none;
}
.term-radio-grid-item input[type='radio'] + label {
  cursor: pointer;
  font-weight: 600;
  font-size: 40px;
  width: 166px;
  padding-top: 24px;
  padding-bottom: 28px;
  border: 1px solid var(--color-charcoal);
  border-radius: 8px;
  background: #fbfbfb;
  box-sizing: border-box;
  color: $complimentary-dark-c;
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.3008px;
}
.structure-radio-grid-item input[type='radio'] + label {
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  width: 166px;
  padding-top: 24px;
  padding-bottom: 28px;
  border: 1px solid var(--color-charcoal);
  border-radius: 8px;
  background: #fbfbfb;
  box-sizing: border-box;
  color: $complimentary-dark-c;
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.3008px;
}
.est-payment {
  color: $med-light-text-color;
}
.term-radio-grid-item input[type='radio']:checked + label,
.term-radio-grid-item input[type='radio'] + label:hover {
  background: var(--color-bg-dawn);
  color: #fff;
  border-radius: 8px;
}

.term-radio-grid-item input[type='radio']:checked + label + .est-payment,
.term-radio-grid-item input[type='radio'] + label:hover + .est-payment {
  color: #fff;
}

@media (max-width: 400px) {
  .term-radio-grid-item {
    width: 100%;
  }
  .term-radio-grid-item input[type='radio'] + label {
    padding-top: 0px;
    width: 100%;
  }
}
/* end eq grid layout */

/* custom radio button/box styles PAYMENT SELECTION */
.radio-box {
  float: left;
  margin: 0 auto 3%;
}
.radio-box input[type='radio'] {
  display: none;
}
.radio-box input[type='radio']:checked + label {
  box-shadow: 0 1px 6px $primary-color;
  background: #eee;
}
.radio-box label {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  display: block;
}
.radio-box-header {
  color: $white-text-color;
  background: $primary-color;
  line-height: 20px;
  font-weight: 500;
  padding: 8px 0 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-transform: uppercase;
}
.terms-only .radio-box-header {
  color: $med-text-color;
  background: $white-text-color;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.radio-box-body {
  @include t2;
  color: $dark-text-color;
  line-height: 1.3em;
  padding: 15px 10px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rb-100 {
  width: 100%;
}
.rb-50 {
  width: 49%;
}
.rb-50:nth-child(odd) {
  margin-right: 2%;
}
.rb-100 .radio-box-body {
  @include s0;
  padding: 25px 10px;
}
.rb-50 .radio-box-body {
  color: $primary-color;
  line-height: 1em;
}
/* end custom radio button/box styles */

/* custom radio button/box styles CHOICE BUTTONS */
.radio-button {
  float: left;
  margin: 0 auto 3%;
}
.radio-button input[type='radio'] {
  display: none;
}
.radio-button label {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  font-weight: lighter;
  padding: 12px;
  margin: 5px;
  width: 120px !important;
  color: $white-text-color;
  background: $primary-color;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  display: block;
}
.loan-type-wrap {
  width: 260px;
  margin: 0 auto;
}
/* end custom radio button/box styles */

/* range input styles */
input[type='range'] {
  appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-moz-focus-outer {
  border: 0;
}
@mixin track() {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #eee;
  background-color: #eee;
}
@mixin thumb() {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background: $primary-color;
  cursor: pointer;
  appearance: none;
  margin-top: -15px;
}
input[type='range'] {
  &::-webkit-slider-runnable-track {
    @include track;
  }
  &::-moz-range-track {
    @include track;
  }
  &::-ms-track {
    @include track;
  }
  &::-webkit-slider-thumb {
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
  &::-ms-thumb {
    @include thumb;
  }
}
/* end of range input sytles */

.second-owner {
  input {
    font-size: 16px !important;
  }
  .ownership-name {
    display: flex;

    .left {
      margin-right: 8px;
    }
    .right {
      margin-left: 8px;
    }
  }
}
.personalLoan {
  .MuiFormLabel-root.Mui-focused {
    color: $hover-color !important;
  }
  .MuiFilledInput-underline:after {
    border-bottom: 2px solid var(--color-charcoal) !important;
  }
}

.input-caption {
  color: $med-light-text-color;
  font-weight: 400;
  display: block;
}
