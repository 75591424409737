@import '../../../../scss/colors';

// payment display styles
.paymentDisplay {
  width: 95%;
  border-spacing: 0;
  border-collapse: collapse;
}
.paymentDisplay td {
  padding: 10px 0;
}
.dailyInterest {
  border-top: solid 1px var(--color-charcoal);
}
.paymentText,
.dailyText {
  text-align: left;
  font-size: 0.8rem;
}
.paymentAmount,
.interestAmount {
  color: $dark-text-color;
  text-align: right;
  font-size: 1.2rem;
}
.dailyText,
.interestAmount {
  font-weight: bold;
}
