@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '../../node_modules/shared-components/app/globals.css';

// Libraries and helpers
// ---------
@import 'lib/lib';
@import 'helpers';

// Variables
// ---------
@import 'colors';

// Reset and base files
// --------------------
@import 'base';

// Layouts
// -------
@import 'layouts';

// Typography
// -------
@import 'typography';

// Fonts
// -------
@import 'fonts';

// Forms
// -----
@import 'forms';

// Overrides
// ---------
@import 'overrides';

// Themes
// ------
@import 'themeDefaultVars.scss';
@import 'components/spinner';

:export {
  colors: colors;
}
