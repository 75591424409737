@import 'typography';

/* react-step-progress-bar */
.RSPBprogression {
  height: 3px;
}

/* modal styles */
.mui-modal p,
.mui-modal h6 {
  margin-bottom: 10px;
  line-height: 1.2em;
}

.modal-close-icon {
  position: absolute;
  top: -35px;
  right: 0;
}

/* end modal styles */

.app-section {
  .MuiChip-root {
    box-sizing: border-box;
    width: 121px;
    height: 26px;
    background: #fafafa;
    border: 1px solid var(--color-charcoal);
    border-radius: 8px;
    @include s4;
    font-weight: 400;
  }
}

header .MuiToolbar-regular {
  height: 62px;
}

header .MuiToolbar-gutters {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 426px) {
  header .MuiToolbar-gutters {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.profile-button.MuiButton-text {
  padding-right: 0;
}

.profile-button.MuiButton-root {
  min-width: 0;
}

.profile-button {
  max-width: 48px;
  box-sizing: border-box;

  .MuiButton-label {
    max-width: 48px;
  }
}

@media (min-width: 426px) {
  header .MuiToolbar-regular {
    height: 68px;
  }
}

#user-dropdown-menu {
  a {
    text-decoration: none;
  }
}

form {
}
